import React from 'react';
import CSSParallax from '../../components/CSSParallax';
import { LAYERS } from '../../constants/parallax';
import theme from '../../styles/theme';
import { StyledSection, StyledContentInner, StyledAbovePadder, StyledBelowPadder } from './styles';

const RockyBeach: React.FunctionComponent<any> = ({ children, isFirstSection, pageHasAnnouncement, natural }) => (
  <StyledSection>
    <CSSParallax.Content isFirstSection={isFirstSection} pageHasAnnouncement={pageHasAnnouncement} natural={natural}>
      <StyledAbovePadder />
      <StyledContentInner>{children}</StyledContentInner>
      <StyledBelowPadder />
      <CSSParallax.Dividers.BrushStroke variant={2} color={theme.colors.fullBlack} />
    </CSSParallax.Content>
    <CSSParallax.Layer depth={2} natural={natural}>
      <CSSParallax.Greeblies.BlueSplatter3 bottom={-24} />
    </CSSParallax.Layer>
    <CSSParallax.Layer depth={-1} appearance={LAYERS.ROCKY_BEACH_FRONT} natural={natural} />
    <CSSParallax.Layer depth={-3} appearance={LAYERS.ROCKY_BEACH_BACK} natural={natural} />
    <CSSParallax.Layer depth={-4} appearance={LAYERS.GREEN_STARS} natural={natural} />
  </StyledSection>
);

export default RockyBeach;
