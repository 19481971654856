import React from 'react';
import HeroBlock from '../../blocks/Hero';
import TicketCategoryBlock from '../../blocks/TicketCategoryBlock';
import ParallaxPage from '../../layout/components/ParallaxPage';
import PurpleSection from '../../sections/Purple';
import TallBlackSection from '../../sections/TallBlack';
import RockyBeachSection from '../../sections/RockyBeach';
import { Section, Ad, Image } from '../../components';
import theme from '../../styles/theme';

class Page extends ParallaxPage {
  constructor(props) {
    super(props);
  }

  renderChildren() {
    const { content } = this.props;

    return (
      <>
        {content && (
          <>
            {content.title && (
              <RockyBeachSection isFirstSection={true} pageHasAnnouncement={this.pageHasAnnouncement()}>
                <HeroBlock title={content.title} textColor={theme.colors.green} />
              </RockyBeachSection>
            )}
            <TallBlackSection
              isFirstSection={!content.title}
              pageHasAnnouncement={this.pageHasAnnouncement()}
              noFooterBlocks={!content.footerBlocks || content.footerBlocks.length === 0}
            >
              <Section.Content>
                <Section.ContentInner hasSidebar={(content.adImage || content.adCode)}>
                  {content.ticketCategories.map((ticketCategory, index) => (
                    <TicketCategoryBlock key={index} title={ticketCategory.name} tickets={ticketCategory.tickets} />
                  ))}
                </Section.ContentInner>
                {(content.adImage || content.adCode) && (
                  <Section.Sidebar>
                    {content.adCode && <Ad {...content.adCode} />}
                    
                    {content.adImage && (
                      <a href={content.adImage.link.url} target="_blank">
                        <Image {...content.adImage.image} />
                      </a>
                    )}
                  </Section.Sidebar>
                )}
              </Section.Content>
              {content.footerBlocks &&
                content.footerBlocks.map((footerBlock, index) => {
                  if (index < content.footerBlocks.length - 1) {
                    return this.renderBlock(footerBlock, index);
                  }
                  return;
                })}
            </TallBlackSection>
          </>
        )}
        {content && content.footerBlocks && content.footerBlocks.length > 0 && (
          <PurpleSection>{this.renderBlock(content.footerBlocks[content.footerBlocks.length - 1])}</PurpleSection>
        )}
      </>
    );
  }
}

export default Page;
